import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _SidebarHamburgerDropdown;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import ApiPanels from "./api-panels";
import Footer from "./footer";
import Sections from "./sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarHamburgerDropdown = (_class = (_SidebarHamburgerDropdown = class SidebarHamburgerDropdown extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor4, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor5, this);
  }
  triggerRenderedAppEvent() {
    this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
  }
  get collapsableSections() {
    if (this.siteSettings.navigation_menu === "header dropdown" && !this.args.collapsableSections) {
      return this.site.mobileView || this.site.narrowDesktopView;
    } else {
      this.args.collapsableSections;
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="hamburger-panel">
      <div
        {{didInsert this.triggerRenderedAppEvent}}
        data-max-width="320"
        class="revamped menu-panel drop-down"
      >
        <div class="panel-body">
          <div class="panel-body-contents">
            <div class="sidebar-hamburger-dropdown">
              {{#if this.sidebarState.showMainPanel}}
                <Sections
                  @currentUser={{this.currentUser}}
                  @collapsableSections={{this.collapsableSections}}
                  @panel={{this.sidebarState.currentPanel}}
                />
              {{else}}
                <ApiPanels
                  @currentUser={{this.currentUser}}
                  @collapsableSections={{this.collapsableSections}}
                />
              {{/if}}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  
*/
{
  "id": "VSeX2kWB",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"hamburger-panel\"],[12],[1,\"\\n      \"],[11,0],[24,\"data-max-width\",\"320\"],[24,0,\"revamped menu-panel drop-down\"],[4,[32,0],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"sidebar-hamburger-dropdown\"],[12],[1,\"\\n\"],[41,[30,0,[\"sidebarState\",\"showMainPanel\"]],[[[1,\"                \"],[8,[32,1],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]],[30,0,[\"sidebarState\",\"currentPanel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[32,2],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n              \"],[8,[32,3],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/hamburger-dropdown.js",
  "scope": () => [didInsert, Sections, ApiPanels, Footer],
  "isStrictMode": true
}), _SidebarHamburgerDropdown), _SidebarHamburgerDropdown), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRenderedAppEvent", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRenderedAppEvent"), _class.prototype)), _class);
export { SidebarHamburgerDropdown as default };