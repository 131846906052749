import ApiSections from "./api-sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarApiPanels = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="sidebar-sections">
    <ApiSections @collapsable={{@collapsableSections}} />
  </div>

*/
{
  "id": "8fYa/tOI",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"sidebar-sections\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@collapsableSections\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-panels.js",
  "scope": () => [ApiSections],
  "isStrictMode": true
}), templateOnly());
export default SidebarApiPanels;