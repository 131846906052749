import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _SidebarUserSections;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import ApiSections from "../api-sections";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import MessagesSection from "./messages-section";
import TagsSection from "./tags-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarUserSections = (_class = (_SidebarUserSections = class SidebarUserSections extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="sidebar-sections">
      <CustomSections @collapsable={{@collapsableSections}} />
      <CategoriesSection @collapsable={{@collapsableSections}} />

      {{#if this.currentUser.display_sidebar_tags}}
        <TagsSection @collapsable={{@collapsableSections}} />
      {{/if}}

      {{#if this.currentUser.can_send_private_messages}}
        <MessagesSection @collapsable={{@collapsableSections}} />
      {{/if}}

      <ApiSections @collapsable={{@collapsableSections}} />
    </div>
  
*/
{
  "id": "bhShkW0u",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-sections\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"display_sidebar_tags\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"can_send_private_messages\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,4],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/sections.js",
  "scope": () => [CustomSections, CategoriesSection, TagsSection, MessagesSection, ApiSections],
  "isStrictMode": true
}), _SidebarUserSections), _SidebarUserSections), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarUserSections as default };